import DecodeProcess from "./DecodeProcess.js";
import WebGLPlayer from "./WebGLPlayer.js";

export default class Player {
  ws = null;
  worker = null;
  timer = null;

  constructor(address, canvas, stateChange) {
    const translate = (btn) => {
      let button = 0;
      switch (btn) {
        case 0:
          button = 1;
          break;
        case 1:
          button = 4;
          break;
        case 2:
          button = 2;
          break;
      }
      return button;
    };
    let active = false;
    canvas.addEventListener("mousedown", (e) => {
      sendEvent({
        type: 0,
        action: 0, //按下(press)
        button: translate(e.button),
        x: e.offsetX,
        y: e.offsetY,
      });
      active = true;
    });
    canvas.addEventListener("mousemove", (e) => {
      if (active) {
        sendEvent({
          type: 0,
          action: 2, //移动(move)
          button: translate(e.button),
          x: e.offsetX,
          y: e.offsetY,
        });
      }
    });
    canvas.addEventListener("mouseup", (e) => {
      sendEvent({
        type: 0,
        action: 1, //释放(release)
        button: translate(e.button),
        x: e.offsetX,
        y: e.offsetY,
      });
      active = false;
    });
    document.oncontextmenu = function() {
      return false;
    };
    document.onkeydown = (event) => {
      if (event.ctrlKey) {
        sendEvent({
          type: 1,
          modifier: 1,
        });
      } else if (event.shiftKey) {
        sendEvent({
          type: 1,
          modifier: 2,
        });
      } else if (event.altKey) {
        sendEvent({
          type: 1,
          modifier: 4,
        });
      }
    };

    const player = new WebGLPlayer(canvas);
    this.worker = new DecodeProcess((data) => {
      player.render(data);
    });

    this.createWS(
      address,
      (data) => {
        // 调用解码器
        this.worker.decode(data);
      },
      "arraybuffer",
      stateChange.bind(this)
    );

    const sendEvent = ({ type, action, button, x, y, modifier }) => {
      if (this.ws && this.ws.readyState === 1) {
        const { clientWidth, clientHeight } = canvas;
        let sendData;
        if (type === 0) {
          // 鼠标事件
          sendData = {
            type,
            button, // 鼠标按键
            action, // 鼠标事件
            position: [x / clientWidth, y / clientHeight],
            modifier: 0, // 键盘符
          };
        } else if (type === 1) {
          // 键盘事件
          sendData = {
            type,
            modifier, // 键盘符
            button: 0,
            action: 0,
            position: [0, 0],
          };
        } else if (type === 2) {
          // 鼠标+键盘事件
          sendData = {
            type,
          };
        }
        this.ws.send(JSON.stringify(sendData));
      }
    };
  }

  // 重连计数
  connCount = 0;
  createWS = (url, cb, binaryType, stateChange) => {
    // websocket开启前提是解码器已经实例化
    if (this.worker) {
      if (this.connCount === 0) {
        stateChange(0, `正在连接${url}...`);
      }
      const ws = new WebSocket(url);
      if (binaryType) {
        ws.binaryType = binaryType;
      }
      ws.onopen = () => {
        stateChange(1, `已连接${url}`);
      };
      ws.onmessage = (e) => {
        cb(e.data);
      };
      // 需要服务端响应了才会触发，因此服务器端延时会造成客户端关闭延时
      ws.onclose = () => {
        this.connCount += 1;
        stateChange(2, `正在重连${url},第${this.connCount}次...`);
        // 服务器断开连接需要自动重连
        this.timer = setTimeout(() => {
          this.createWS(url, cb, binaryType, stateChange);
        }, 1500);
      };
      this.ws = ws;
    }
  };

  dispose() {
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    if (this.worker) {
      this.worker.close();
      this.worker = null;
    }
  }
}
