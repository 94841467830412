var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body"},[_c('div',{staticClass:"box"},[_c('img',{attrs:{"src":require("../assets/login.png")}}),_c('div',{staticClass:"form"},[_c('h3',[_vm._v("欢迎登录")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'userName',
              {
                rules: [{ required: true, message: '请输入用户名!' }],
              } ]),expression:"[\n              'userName',\n              {\n                rules: [{ required: true, message: '请输入用户名!' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入用户名"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'pwd',
              {
                rules: [{ required: true, message: '请输入密码!' }],
              } ]),expression:"[\n              'pwd',\n              {\n                rules: [{ required: true, message: '请输入密码!' }],\n              },\n            ]"}],attrs:{"type":"password","placeholder":"请输入密码"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"submit",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 登录 ")])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }