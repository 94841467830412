import _ from "lodash";
/* eslint-disable no-undef */
export default class DecodeProcess {
  decodedCb = null;
  decoder = null;
  queue = [];
  timer = null;

  constructor(_decodedCb) {
    this.decodedCb = _decodedCb;
    const _decoder = new Module.Decoder();
    _decoder.open(this.ondecode.bind(this));
    this.decoder = _decoder;

    this.timer = setInterval(() => {
      if (this.decoder) {
        // 合并队列中Uint8Array
        const data = _.reduce(
          this.queue,
          (arr, o) => {
            0;
            const newArray = _.concat(arr, Array.from(o));
            return newArray;
          },
          []
        );
        const buf = Module._malloc(data.length);
        try {
          Module.HEAPU8.set(data, buf);
          this.decoder.send_pkt(buf, data.length);
        } finally {
          Module._free(buf);
          // 队列清空
          this.queue.length = 0;
          // this.queue = [];
        }
      }
    }, 30);
  }

  ondecode(frame) {
    if (frame != null) {
      frame.bytes_y = frame.get_bytes_y();
      frame.bytes_u = frame.get_bytes_u();
      frame.bytes_v = frame.get_bytes_v();
      this.decodedCb(frame);
      frame.delete();
    } else {
      console.log("ws frame is null: decode error or need new packets");
    }
  }

  decode(_data) {
    // 解码数据放入队列
    const data = new Uint8Array(_data);
    this.queue.push(data);
  }

  // 关闭
  close() {
    clearInterval(this.timer);
    if (this.decoder) {
      this.decoder.close();
      this.decoder = null;
    }
  }
}
