import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import "./antd.js";
import App from "./App.vue";
import moment from "moment";
import router from "./router";
import axios from "axios";
import store from "./store";
import fullscreen from "vue-fullscreen";
Vue.use(fullscreen);

require("./mock/index.js");

Vue.prototype.$moment = moment; // 赋值使用
moment.locale("zh-cn"); // 需要汉化

// 获取环境设置的接口地址
axios.defaults.baseURL = "/api";
axios.default.timeout = 5000;

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("Authorization");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加返回拦截器，判断是否401
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // token过期并且非登录页
        if (router.currentRoute.name !== "login") {
          sessionStorage.removeItem("Authorization");
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
        }
      }
    }
    return Promise.reject(error.response.data);
  }
);
Vue.prototype.$myaxios = axios;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
