// 引入mockjs
import Mock from "mockjs";
import _ from "lodash";

const responseData = {
  success: true,
  data: Object,
  msg: null,
};

const random = Mock.Random;

// 拦截ajax请求，配置mock的数据
const db = [
  {
    userName: "shenhua",
    pwd: "sh142536",
    ships: [
      {
        name: "神华805",
        user: "marautec-sh805",
        pwd: "sh-805",
        wsUrl: "ws://58.33.117.172:30196/FHD",
      },
    ],
  },
];

// ------------------登录----------------------
Mock.mock(/\/api\/login/, "post", (req) => {
  const { userName, pwd } = JSON.parse(req.body);
  console.log("login", userName, pwd);
  const result = { ...responseData };

  // 从数据中获取匹配的用户
  const user = _.find(db, { userName, pwd });
  if (user) {
    result.data = { user, token: random.guid() };
  } else {
    result.success = false;
    result.msg = "用户名或密码错误";
  }
  return result;
});

console.log("%c前端 mock 环境启动成功", "color: #38f;font-weight: bold");

export default Mock;
