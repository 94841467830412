import Vue from "vue";
import Router from "vue-router";
import Login from "@/pages/Login";
import Live from "@/pages/Live";
import Player from "@/pages/Player";

Vue.use(Router);

const VueRouterPush = Router.prototype.push;
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        title: "登录",
      },
    },
    {
      path: "/live",
      name: "live",
      component: Live,
      meta: {
        title: "前视融合",
      },
    },
    {
      path: "/player",
      name: "player",
      component: Player,
      meta: {
        title: "前视融合",
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // savedPosition 当且仅当 popstate 导航 (通过浏览器的 前进/后退 按钮触发) 时才可用。
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// 路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `全景视觉-${to.meta.title}`;
  }

  // 根据设置的requireAuth客户端简单验证token是否存在
  if (to.matched.some((r) => r.meta.requireAuth)) {
    let token = sessionStorage.getItem("Authorization");
    if (token) {
      next();
    } else {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
