<template>
  <div class="body">
    <div class="box">
      <img src="../assets/login.png" />
      <div class="form">
        <h3>欢迎登录</h3>
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
              v-decorator="[
                'userName',
                {
                  rules: [{ required: true, message: '请输入用户名!' }],
                },
              ]"
              placeholder="请输入用户名"
            >
              <a-icon
                slot="prefix"
                type="user"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="[
                'pwd',
                {
                  rules: [{ required: true, message: '请输入密码!' }],
                },
              ]"
              type="password"
              placeholder="请输入密码"
            >
              <a-icon
                slot="prefix"
                type="lock"
                style="color: rgba(0,0,0,.25)"
              />
            </a-input>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" class="submit">
              登录
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store
            .dispatch("login", {
              ...values,
            })
            .then(() => {
              this.$router.push({ path: "/player" });
            })
            .catch((err) => {
              this.$message.error(err);
            });
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.body {
  height: 100%;
  background: #242731;
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 860px;
    height: 450px;
    background: #2d313f;
    border-radius: 8px;
    display: flex;
    justify-content: stretch;

    img {
      width: 541px;
    }

    .form {
      width: calc(860px - 541px);
      padding: 50px;

      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 21px;
        letter-spacing: 2px;
        color: #ffffff;
        margin-bottom: 60px;
      }

      .forgot {
        float: right;
        display: inline;
      }

      .submit {
        width: 100%;
      }
    }
  }
}
</style>
