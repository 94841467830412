<template>
  <fullscreen
    ref="fullscreen"
    @change="fullscreenChange"
    class="canvas-wrapper"
  >
    <div v-if="stateMsg !== null && stateMsg.state !== 1" class="stateMsg">
      {{ stateMsg.msg }}
    </div>
    <canvas ref="videoPlayer" />
    <a-button
      class="screenbtn"
      type="primary"
      shape="circle"
      size="large"
      :icon="fullscreen ? 'fullscreen-exit' : 'fullscreen'"
      @click="
        () => {
          this.$refs['fullscreen'].toggle();
        }
      "
    />
  </fullscreen>
</template>
<script>
import Player from "./Player";

export default {
  name: "VideoPlayer",
  data() {
    return {
      player: null,
      stateMsg: null,
      fullscreen: false,
    };
  },
  props: ["address"],
  watch: {
    address(newValue, oldValue) {
      if (newValue !== oldValue) {
        // 降频
        clearTimeout(this.addressTimer);
        this.addressTimer = setTimeout(() => {
          if (newValue) {
            this.initPlayer(newValue);
          } else {
            this.doClose();
          }
        }, 500);
      }
    },
  },
  destroyed() {
    this.doClose();
  },
  methods: {
    // 接收ws视频流
    initPlayer(address) {
      if (!address) {
        return;
      }
      this.doClose();

      this.player = new Player(
        address,
        this.$refs.videoPlayer,
        (state, msg) => {
          this.stateMsg = { state, msg };
        }
      );
    },
    doClose() {
      if (this.player) {
        this.player.dispose();
        this.player = null;
      }
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
  },
};
</script>
<style lang="less" scoped>
.canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #242731;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  user-select: none;

  .stateMsg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9999;
    font-size: 12px;
    background-color: #dbdee3;
    padding: 3px;
    border-top-right-radius: 5px;
  }

  canvas {
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    background: none;
    border: 0;
  }

  .screenbtn {
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 999;
  }
}
</style>
