<template>
  <VideoPlayer :address="videoWS"></VideoPlayer>
</template>

<script>
import VideoPlayer from "@/components/CanvasPlayer/Index.vue";

export default {
  data() {
    return {
      videoWS: null,
    };
  },
  components: {
    VideoPlayer,
  },
  mounted() {
    if (this.ship) {
      const { name, wsUrl, user, pwd } = this.ship;
      document.title = `全景视觉-${name}`;
      const token = btoa(JSON.stringify({ user, pwd }));
      this.videoWS = `${wsUrl}?token=${token}`;
    } else {
      this.$message.error("无法获取船舶信息");
      this.$router.push({ path: "/login" });
    }
  },
  computed: {
    ship() {
      const userData = this.$store.getters.user;
      if (userData) {
        return userData.ships[0];
      } else {
        return null;
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
