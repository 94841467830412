<template>
  <div class="box">
    <div class="left">
      <a-menu
        mode="inline"
        @click="
          ({ item, key }) => {
            onConnect(key);
          }
        "
      >
        <template v-for="(o, i) in menus">
          <a-menu-item :key="i">
            <span>{{ o.name }}</span>
          </a-menu-item>
          <a-divider :key="`line_${i}`" />
        </template>
      </a-menu>
    </div>
    <div class="right">
      <div class="video">
        <VideoPlayer :address="videoWS"></VideoPlayer>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/CanvasPlayer/Index.vue";

export default {
  data() {
    return {
      videoWS: null, // 选中的流媒体地址
    };
  },
  computed: {
    menus() {
      const userData = this.$store.getters.user;
      if (userData) {
        return userData.ships;
      } else {
        return [];
      }
    },
  },
  methods: {
    onConnect(index) {
      const item = this.menus[index] || {};
      const { wsUrl, user, pwd } = item;
      if (wsUrl && user && pwd) {
        const token = btoa(JSON.stringify({ user, pwd }));
        this.videoWS = `${wsUrl}?token=${token}`;
      }
    },
  },
  components: {
    VideoPlayer,
  },
};
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background-color: white;

  .left {
    width: 100px;
    height: 100%;
    padding: 1rem 0;
    overflow-y: auto;
  }

  .right {
    flex: 1;
    height: 100%;

    .video {
      width: 100%;
      height: 100%;
      background-color: #242731;
      position: relative;
    }
  }
}
</style>
