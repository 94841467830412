/* vuex用于组件之间数据共享，独立的数据不建议使用 */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    setLogin(state, data) {
      sessionStorage.setItem("Authorization", data.token);
      sessionStorage.setItem("UserInfo", JSON.stringify(data.user));
      state.user = data.user;
    },
    setLoginOut(state) {
      sessionStorage.removeItem("UserInfo");
      sessionStorage.removeItem("Authorization");
      state.user = null;
      router.push("/login");
    },
  },
  getters: {
    user: (state) => {
      return state.user || JSON.parse(sessionStorage.getItem("UserInfo"));
    },
  },
  actions: {
    login({ commit }, obj) {
      return new Promise((resolve, reject) => {
        axios
          .post("/login", JSON.stringify(obj))
          .then((response) => {
            const { success, data, msg } = response.data;
            if (success) {
              if (data) {
                commit("setLogin", data);
                resolve();
              } else {
                reject(new Error("用户信息不存在"));
              }
            } else {
              reject(msg);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
